/**
 * @module sitn.feedback.module
 */
import angular from 'angular';
import ngeoLocation from 'ngeo/statemanager/Location.js';

const exports = angular.module('feedback', [ngeoLocation.name]);

exports.Component = {
  controller: 'sitnFeedbackController',
  bindings: {
    show_modal: '=sitnFeedbackShow',
  },
  templateUrl: () => 'sitnyon/feedback',
};

exports.run(
  /* @ngInject */ ($templateCache) => {
    $templateCache.put('sitnyon/feedback', require('./feedback.html'));
  }
);

exports.component('sitnFeedback', exports.Component);

/**
 * @param {!angular.Scope} $scope Angular scope.
 * @param {!angular.$http} $http Angular http service.
 * @param {string} sitnFeedbackUrl URL of the SITN feedbackservice webservice.
 * @param {ngeo.statemanager.Location} ngeoLocation ngeo Location service.
 * @constructor
 * @private
 * @ngInject
 */
exports.Controller = function ($scope, $http, sitnFeedbackUrl, ngeoLocation) {
  /**
   * @type {boolean}
   * @private
   */
  this.show_modal;

  /**
   * @type {angular.$http}
   * @private
   */
  this.http_ = $http;

  /**
   * @type {string}
   * @private
   */
  this.url_ = sitnFeedbackUrl;

  /**
   * @type {!ngeo.statemanager.Location}
   * @private
   */
  this.ngeoLocation_ = ngeoLocation;

  /**
   * @type {string}
   * @private
   */
  this.permalink;

  /**
   * @type {boolean}
   * @export
   */
  this.feedback_send = false;

  /**
   * @type {string}
   * @export
   */
  this.email;

  /**
   * @type {string}
   * @export
   */
  this.name;

  /**
   * @type {string}
   * @export
   */
  this.reason;

  /**
   * @type {string}
   * @export
   */
  this.feedback_text;

  $scope.names = [
    'Poser une question',
    'Signaler un problème',
    'Donner un avis',
    'Demander un accès sécurisé',
  ];

  $scope.$watch(
    // First arg = value to watch
    function () {
      return this.feedback_send;
    }.bind(this),
    function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === true) {
          this.feedbackSubmit();
        }
      }
    }.bind(this)
  );
  $scope.$watch(
    // First arg = value to watch
    function () {
      return this.show_modal;
    }.bind(this),
    function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === true) {
          this.getPermalink();
        }
      }
    }.bind(this)
  );
};

exports.Controller.prototype.feedbackSubmit = function () {
  const name = this.name;
  const email = this.email;
  const reason = this.reason;
  const feedback_text = this.feedback_text;
  const user_agent = navigator.userAgent;

  if (name === null) {
    alert('Veuillez indiquer un nom.');
    return;
  }

  if (email === null) {
    alert('Veuillez indiquer une adresse email.');
    return;
  }

  if (email && email !== '' && email.indexOf('@') === -1) {
    alert("L'adresse email n'est pas valide");
    return;
  }

  if (reason === null) {
    alert('Veuillez choisir la raison du contact.');
    return;
  }

  if (feedback_text === null) {
    alert('Veuillez écrire un message.');
    return;
  }

  if (feedback_text && feedback_text.length > 1000) {
    alert('Votre texte est trop long (max 1000 caractères).');
    return;
  }

  this.http_
    .post(
      this.url_,
      $.param({
        'name': name,
        'email': email,
        'reason': reason,
        'feedback': feedback_text,
        'permalink': this.permalink,
        'user_agent': user_agent,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        },
      }
    )
    .then(
      function successCallback(response) {
        this.show_modal = false;
        alert(['Votre message a été transmis, nous vous en remercions.'].join(''));
      }.bind(this),
      function errorCallback(response) {
        this.show_modal = false;
        alert('Une erreur est survenue. Merci de nous contacter par email (info@cartolacote.ch)');
      }.bind(this)
    );
};

exports.Controller.prototype.getPermalink = function () {
  this.permalink = this.ngeoLocation_.getUriString();
};

exports.controller('sitnFeedbackController', exports.Controller);

export default exports;
