/**
 * @module sitnyon.rasterstatistics.module
 */
import angular from 'angular';
import olFormatGeoJSON from 'ol/format/GeoJSON.js';
import olSourceVector from 'ol/source/Vector.js';
import olLayerVector from 'ol/layer/Vector.js';
import olInteractionDraw from 'ol/interaction/Draw.js';
import olGeomLineString from 'ol/geom/LineString.js';
import ngeoToolActivate from 'ngeo/misc/ToolActivate.js';
import ngeoToolActivateMgr from 'ngeo/misc/ToolActivateMgr.js';

const exports = angular.module('rasterstatistics', [ngeoToolActivateMgr.name]);

/**
 *
 * @return {angular.Directive} The address directive.
 */
exports.Directive = function () {
  return {
    controller: 'sitnyonRasterstatisticsController as ctrl',
    bindToController: true,
    scope: {
      'map': '<', // one way Angular binding !
      'active': '=',
      'layers': '<',
      'maxarea': '<',
      'minarea': '<',
    }, // all the map values are available (this.map is binded) (it defines how the values are transfered)
    templateUrl: 'sitnyon/rasterstatistics',
  };
};

exports.run(
  /* @ngInject */ ($templateCache) => {
    $templateCache.put('sitnyon/rasterstatistics', require('./rasterstatistics.html'));
  }
);

exports.directive('sitnyonRasterstatistics', exports.Directive);

/**
 * @param {!angular.Scope} $scope Angular scope.
 * @param {!angular.$http} $http Angular http service.
 * @param {ngeo.misc.ToolActivateMgr} ngeoToolActivateMgr Ngeo ToolActivate manager
 *     service.
 * @param {string} sitnyonRasterUrl URL of the SITNyon raster service.
 * @constructor
 * @private
 * @ngInject
 */
exports.Controller = function ($scope, $http, ngeoToolActivateMgr, sitnyonRasterUrl) {
  /**
   * @type {angular.Scope}
   * @private
   */
  this.$scope_ = $scope;

  /**
   * @type {angular.$http}
   * @private
   */
  this.$http_ = $http;

  /**
   * @type {Array<string>}
   */
  this.layers;

  /**
   * @type {number}
   */
  this.maxarea;

  /**
   * @type {number}
   */
  this.minarea;

  /**
   * @type {boolean}
   * @export
   */
  this.hidetext = true;

  /**
   * @type {boolean}
   * @export
   */
  this.showcog = false;

  /**
   * @type {boolean}
   * @export
   */
  this.showresults = false;

  /**
   * @type {boolean}
   * @export
   */
  this.showerror = false;

  /**
   * @type {string}
   * @export
   */
  this.errormessage;

  /**
   * @type {ol.Map}
   * @export
   */
  this.map;

  /**
   * @type {boolean}
   */
  this.active;

  /**
   * @type {Object}
   */
  this.data = {};

  /**
   * @type {string}
   * @private
   */
  this.sitnyonRasterUrl_ = sitnyonRasterUrl;

  /**
   * @type {ngeo.misc.ToolActivateMgr}
   * @private
   */
  this.ngeoToolActivateMgr_ = ngeoToolActivateMgr;

  /**
   * @type {ol.source.Vector}
   */
  this.source = new olSourceVector();

  /**
   * @type {ol.layer.Vector}
   */
  this.vectorLayer;

  /**
   * @type {ol.interaction.Draw}
   */
  this.interaction;

  // Initialize the tools inside of the tool manager
  this.tool = new ngeoToolActivate(this, 'active');
  this.ngeoToolActivateMgr_.registerTool('mapTools', this.tool, false);

  $scope.$watch(
    // First arg = value to watch
    function () {
      return this.active;
    }.bind(this),
    function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateEventsListening_(newValue);
      }
    }.bind(this)
  );
};

exports.Controller.prototype.$onInit = function () {
  this.vectorLayer = new olLayerVector({
    source: this.source,
  });
  this.vectorLayer.setMap(this.map);

  this.interaction = new olInteractionDraw(
    /** @type {olx.interaction.DrawOptions} */ ({
      type: 'Polygon',
      source: this.vectorLayer.getSource(),
    })
  );

  this.interaction.on(
    'drawend',
    function (e) {
      this.drawEnd_(e.feature);
    }.bind(this)
  );

  this.interaction.on(
    'drawstart',
    function (e) {
      this.source.clear();
      this.showresults = false;
    }.bind(this)
  );

  this.interaction.setActive(false);
  this.map.addInteraction(this.interaction);
};
/**
 * @param {boolean} activate Activation state of the plugin
 * @private
 */
exports.Controller.prototype.updateEventsListening_ = function (activate) {
  if (activate === true) {
    this.ngeoToolActivateMgr_.activateTool(this.tool);
    this.interaction.setActive(true);
  } else {
    this.ngeoToolActivateMgr_.deactivateTool(this.tool);
    this.interaction.setActive(false);
    this.source.clear();
    this.hidetext = true;
    this.showcog = false;
    this.showresults = false;
    this.showerror = false;
  }
};

/**
 * @param {ol.Feature} feature Openlayers feature which was drawn
 * @private
 */
exports.Controller.prototype.drawEnd_ = function (feature) {
  var geom = /** @type {ol.geom.Polygon} */ (feature.getGeometry());
  var area = geom.getArea();

  if (area > this.maxarea) {
    // TODO: Does not work (the text is displayed only after a map pan)...
    /*this.errormessage = 'La surface dessinée est supérieure à la limite autorisée de ' + this.maxarea + ' m<sup>2</sup>.';
    this.showerror = true;*/
    alert('La surface dessinée est supérieure à la limite autorisée de ' + this.maxarea + ' m2.');
    return;
  } else if (area < this.minarea) {
    // TODO: Does not work (the text is displayed only after a map pan)...
    /*this.errormessage = 'La surface dessinée est inférieure à la limite autorisée de ' + this.minarea + ' m<sup>2</sup>.';
    this.showerror = true;*/
    alert('La surface dessinée est inférieure à la limite autorisée de ' + this.minarea + ' m2.');
    return;
  }

  var geosjon = new olFormatGeoJSON().writeFeatures([feature]);
  this.hidetext = false;
  this.showcog = true;
  this.showerror = false;

  this.$http_
    .post(this.sitnyonRasterUrl_, {
      'feature': geosjon,
      'layers': this.layers,
    })
    .then(
      function successCallback(response) {
        this.updatePanel_(response.data);
      }.bind(this),
      function errorCallback(response) {
        this.showcog = false;
        alert('Erreur lors de la requête.'); // TODO: Do not use alert
      }
    );
};

/**
 * @param {Object} results Returned object from the webservice
 * @private
 */
exports.Controller.prototype.updatePanel_ = function (results) {
  results = results['layer_statistics'];
  this.showcog = false;
  this.showresults = true;
  var feature = this.source.getFeatures()[0];
  var geom = /** @type {ol.geom.Polygon} */ (feature.getGeometry());

  var coordinates = geom.getCoordinates();
  var line = new olGeomLineString(coordinates[0]);

  this.data = {
    'geom': {
      'area': geom.getArea(),
      'perimeter': line.getLength(),
    },
    'population': {
      'habitants': results['stats_habitants']['results']['sum'],
      'menages': results['stats_menages']['results']['sum'],
    },
    'emplois': {
      'emplois': results['stats_emplois']['results']['sum'],
      'ept': results['stats_ept']['results']['sum'],
    },
    'logements': {
      'surface_logements': results['stats_surface_logements']['results']['sum'],
      'pieces_logements': results['stats_pieces_logements']['results']['sum'],
    },
    'energie': {
      'surface_reference_energetique': results['stats_surface_reference_energetique']['results']['sum'],
      'consommation_electrique': results['stats_consommation_electrique']['results']['sum'],
      'consommation_thermique': results['stats_consommation_thermique']['results']['sum'],
    },
  };
};

exports.controller('sitnyonRasterstatisticsController', exports.Controller);

export default exports;
